<template>
	<layouts-basic>
		<div class="pb-7" v-if="!user.phone">
			<h3 class="ls-tight fw-bolder h3">Registrá tu número de teléfono</h3>
			<p class="text-sm text-muted">Para registrar tu número de teléfono, completá el siguiente formulario.</p>
		</div>
		<div class="pb-7" v-else>
			<h3 class="ls-tight fw-bolder h3">Actualizar número de teléfono</h3>
			<p class="text-sm text-muted">Para actualizar tu número de teléfono, completá el siguiente formulario.</p>
		</div>
		<form @submit.prevent="submit">
			<common-form-input-phone v-model="phone" title="Teléfono" placeholder="Ingresá tu número telefónico" :validation="validation.phone" :focus="true" class="mb-3" />
			<div class="mb-3 text-center" v-if="validationExternal.length > 0">
				<div class="invalid-feedback d-block" v-for="(error, index) in validationExternal" v-bind:key="index">{{ error }}</div>
			</div>
			<div class="mt-5">
				<button type="submit" class="btn btn-primary w-100" :disabled="submitting" v-if="!user.phone">Registrar número</button>
				<button type="submit" class="btn btn-primary w-100" :disabled="submitting" v-else>Actualizar número</button>
			</div>
		</form>
		<div class="mt-5">
			<button type="button" class="btn btn-neutral w-100" @click="redirectBack">Cancelar</button>
		</div>
	</layouts-basic>
</template>

<script>
	import { reactive, toRefs, computed } from 'vue';
	import store from '@/store';
	import useVuelidate from '@vuelidate/core';
	import { helpers } from '@vuelidate/validators';
	import { isValidPhoneNumber, parsePhoneNumber, validatePhoneNumberLength } from 'libphonenumber-js';
	import MeRepository from '@/repositories/MeRepository';
	import composableRedirect from '@/composables/redirect';
	import composableForm from '@/composables/form';
	import _ from 'lodash';

	export default {
		setup() {
			const { redirectBack, redirectNext } = composableRedirect();
			const { submitting, validationExternal, catchError } = composableForm();
			const user = computed(() => store.getters['auth/user']);

			const form = reactive({
				phone: ''
			});

			const validatePhone = (value) => {
				return isValidPhoneNumber(value);
			}

			const validatePhoneMessage = ({ $model }) => {
				var message = null;

				switch(validatePhoneNumberLength($model)) {
					case 'NOT_A_NUMBER':
						message = 'Debe ser numérico';
						break;
					case 'INVALID_COUNTRY':
						message = 'Debe incluir el código de país, incluyendo el signo +';
						break;
					case 'TOO_SHORT':
						message = 'Es demasiado corto';
						break;
					case 'INVALID_LENGTH':
						message = 'Contiene una longitud inválida';
						break;
					case 'TOO_LONG':
						message = 'Es demasiado largo';
						break;
					default:
						message = 'Es inválido';
						break;
				}

				return message;
			}

			const validation = useVuelidate({
				phone: {
					validatePhone: helpers.withMessage(validatePhoneMessage, validatePhone)
				}
			}, form);

			async function submit() {
				validationExternal.value = [];
				if(!await validation.value.$validate()) return;

				//Formatea el teléfono
				var sendForm = _.clone(form);
				sendForm.phone = parsePhoneNumber(sendForm.phone).number;

				submitting.value = true;
				MeRepository.updatePhone(sendForm).then(onSuccess).catch(catchError);
			}

			const onSuccess = (response) => {
				store.dispatch('auth/setUser', response.data).then(redirectNext).catch(catchError);
			}

			return { redirectBack, user, ...toRefs(form), validation, validationExternal, submitting, submit };
		}
	}
</script>